<template>
  <div>
    <individual-user-form
      :entity="user"
      :submit="addEntity"
    />
  </div>
</template>

<script>
import IndividualUserForm from '@/common/components/UserEntities/IndividualUserForm.vue'
import handleAlerts from '@/common/compositions/common/handleAlerts'
import handleFormData from '@/common/compositions/common/handleFormData'
import UserEntity from '@/common/compositions/UserEntity/userEntityAPI'

export default {
  name: 'IndividualUserSignUp',
  components: { IndividualUserForm },
  setup() {
    const { setFormData, formData } = handleFormData()
    const { successfulOperationAlert } = handleAlerts()
    const { entity, storeRequest } = UserEntity()
    return {
      entity, storeRequest, successfulOperationAlert, setFormData, formData,
    }
  },
  methods: {
    addEntity() {
      this.$set(this.entity, 'classification', 'Individuals')
      this.$delete(this.entity, 'location')
      return this.storeRequest().then(() => {
        this.successfulOperationAlert('Entity is registered successfully')
        this.$router.push({
          name: 'login',
        })
      })
    },
  },
}
</script>

<style>

</style>
