<template>
  <b-container class="mt-2">
    <validation-observer #default="{handleSubmit}">
      <b-card header="Signup as Individual">
        <b-form @submit.prevent>
          <div class="border p-2 mb-2">
            <h4 class="mb-2">
              Registration Data
            </h4>
            <b-row>
              <b-col md="6">
                <text-input
                  id="first-name"
                  name="First Name"
                  rules="required|alpha|min:3|max:50"
                  :text.sync="user.user_first_name"
                />
              </b-col>
              <b-col md="6">
                <text-input
                  id="last-name"
                  name="Last Name"
                  rules="required|alpha|min:3|max:50"
                  :text.sync="user.user_last_name"
                />
              </b-col>
              <b-col md="6">
                <text-input
                  id="email"
                  name="Email"
                  rules="required|email"
                  :text.sync="user.user_email"
                />
              </b-col>
              <b-col md="6">
                <text-input
                  id="password"
                  name="Password"
                  type="password"
                  rules="required|password"
                  :text.sync="user.user_password"
                />
              </b-col>
              <b-col md="6">
                <text-input
                  id="phone"
                  name="Mobile"
                  type="number"
                  rules="required|phone"
                  :text.sync="user.user_mobile"
                />
              </b-col>
              <b-col md="6">
                <subclass-select
                  label="User type"
                  :multiple="true"
                  rules="required"
                  :class-id="classLookup['Individuals']"
                  :subclass.sync="user.subclassifications"
                />
              </b-col>
              <b-col md="12">
                <b-checkbox v-model="user.terms">
                  Accept Terms and conditions
                </b-checkbox>
              </b-col>
            </b-row>
          </div>
          <div class="border p-2 mb-2">
            <h4 class="mb-2">
              Business Data
            </h4>
            <b-row>
              <b-col md="6">
                <text-input
                  id="entity-name"
                  name="Entity Name"
                  rules="required|min:3|max:50"
                  :text.sync="user.name"
                />
              </b-col>
              <b-col md="12">
                <upload-files
                  id="media"
                  label="Media"
                  rules="size:5000|max:5"
                  multiple
                  accept="image/*"
                  :files.sync="user.media_files"
                />
                <images-preview
                  :images.sync="user.media_files"
                />
              </b-col>
              <b-col md="12">
                <text-area-input
                  id="description"
                  name="Description"
                  placeholder="Brief about place"
                  :text.sync="user.description"
                />
              </b-col>
            </b-row>
          </div>
          <div class="border p-2 mb-2">
            <h4 class="mb-2">
              Organization social links
            </h4>
            <b-row>
              <b-col md="6">
                <text-input
                  id="facebook"
                  name="Facebook"
                  rules="facebook-url"
                  :text.sync="user.facebook"
                />
              </b-col>
              <b-col md="6">
                <text-input
                  id="twitter"
                  name="Twitter"
                  rules="twitter-url"
                  :text.sync="user.twitter"
                />
              </b-col>
              <b-col md="6">
                <text-input
                  id="instagram"
                  name="Instagram"
                  rules="instagram-url"
                  :text.sync="user.instagram"
                />
              </b-col>
              <b-col md="6">
                <text-input
                  id="website"
                  name="Website"
                  rules="url"
                  :text.sync="user.website"
                />
              </b-col>
            </b-row>
          </div>
          <div class="text-right">
            <back class="mr-2" />
            <submit-button
              v-bind="{submit,handleSubmit}"
              :disabled="!user.terms"
            />
          </div>
        </b-form>
      </b-card>
    </validation-observer>
  </b-container>
</template>

<script>
import Back from '../common/FormInputs/Back.vue'
import ImagesPreview from '../common/FormInputs/ImagesPreview.vue'
import SubclassSelect from '../common/FormInputs/SubclassSelect.vue'
import SubmitButton from '../common/FormInputs/SubmitButton.vue'
import TextAreaInput from '../common/FormInputs/TextAreaInput.vue'
import TextInput from '../common/FormInputs/TextInput.vue'
import UploadFiles from '../common/FormInputs/UploadFiles.vue'

export default {
  name: 'IndvidualUserForm',
  components: {
    TextInput,
    UploadFiles,
    ImagesPreview,
    TextAreaInput,
    SubmitButton,
    Back,
    SubclassSelect,
  },
  props: {
    user: { type: Object, default: () => ({}) },
    submit: { type: Function, default: () => {} },
  },
  computed: {
    classLookup() {
      return {
        Individuals: 31,
      }
    },
  },
}
</script>

<style>

</style>
